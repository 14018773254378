/* You can add global styles to this file, and also import other style files */
@use '@carbon/styles/scss/themes';
@use '@carbon/styles/scss/theme';
@use '@carbon/styles';
@use '@angular/material'as mat;
@import './app/mandants/digi/app-theme.scss';
@include mat.core();
$cocloud_2-primary: mat.define-palette($md-primary);
$cocloud_2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$cocloud_2-warn: mat.define-palette(mat.$red-palette);
$cocloud_2-theme: mat.define-light-theme((color: (primary: $cocloud_2-primary,
      accent: $cocloud_2-primary,
      warn: $cocloud_2-warn,
    )));
@include mat.all-component-themes($cocloud_2-theme);
@import "@carbon/charts/styles.css";


@import "@carbon/styles/css/styles.css";
@import "@carbon/charts/styles.css";


$paddingRowLeftAndRight: 10vw;
$paddingRowLeftAndRightMedium: 10rem;
$paddingRowTopAndBotttom: 10vh;
$marginBetweenRows: 10vh;
$mobileRowWidth: 100vw;


$paddingMedium : 5vw;
$paddingLarge : 10vw;
$paddingRowMobile768 : 2rem;
//breakpoints
$breakpointMedium : 1200px;
$breakpointLarge : 1650px;
$breakpointSmall : 768px;


$grid-breakpoints: (xs: 0,
  sm: 600px,
  md: 800px,
  lg: 1000px,
  xl: $breakpointLarge);

// Real_Head_Web
* {
  font-family: var(font-family), 'Open Sans', sans-serif;
}


:root {
  // Emit CSS Custom Properties for the current theme
  @include theme.theme();
}

.white {
  @include theme.theme(themes.$white);
}

.g10 {
  @include theme.theme(themes.$g10);
}

.g90 {
  @include theme.theme(themes.$g90);
}

.g100 {
  @include theme.theme(themes.$g100);
}


@font-face {
  font-family: "Integral CF";
  src: local("Integral CF"), url("./assets/fonts/Integral/IntegralCF.ttf") format("truetype");
}

@font-face {
  font-family: "Integral CF Cursiv";
  src: local("Integral CF Cursiv"), url("./assets/fonts/Integral/IntegralCFCursiv.ttf") format("truetype");
}

@font-face {
  font-family: Real_Head_Web;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/RealHeadWebW03Book/Real_Head_Web_Regular.otf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-Book.ttf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-Medium.ttf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-Black.ttf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-BookItalic.ttf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-MediumItalic.ttf');
  font-display: fallback;
}

@font-face {
  font-family: QuaySans;
  font-style: italic;
  font-weight: 600;
  src: url('./assets/fonts/QuaySansITC/QuaySansITC-BlackItalic.ttf');
  font-display: fallback;
}

@font-face {
  font-family: F37;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/F37_Ginger_Pro/F37GingerPro-Regular.otf');
  font-display: fallback;
}

@font-face {
  font-family: F37;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/F37_Ginger_Pro/F37GingerPro-Demi.otf');
  font-display: fallback;
}

@font-face {
  font-family: Bronova;
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Bronova/Bronova_Regular.ttf');
  font-display: fallback;
}

@font-face {
  font-family: Bronova;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Bronova/Bronova_Bold.ttf');
  font-display: fallback;
}


@font-face {
  font-family: Open_Sans;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');
  font-display: fallback;
}


@font-face {
  font-family: Source_Sans_Pro;
  font-style: normal;
  font-weight: 500;
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  font-display: fallback;
}

html,
body {
  height: 100%;
}

